<template>
  <div>
    <!-- Filters -->
    <basic-filters
      @basic-filters-search="search"
      @basic-filters-changed="(v) => (filters = v)"
      @basic-not-filters-changed="(v) => (not_filters = v)"
      :allowed_filters="allowed_filters"
      :suburb_set_to_use="suburb_set_to_use"
      page="propertysearch"
    />
    <property-sidebar :sidebar="sidebar" :listing="sidebar_listing" @toggle_sidebar="(val) => (sidebar = val)" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- card header -->
      <b-card-header header-tag="nav" class="pb-0 mb-0">
        <b-nav card-header tabs>
          <b-nav-item :active="active_view == 'table'" @click="active_view = 'table'">Table View</b-nav-item>
          <b-nav-item :active="active_view == 'map'" @click="active_view = 'map'">Map View</b-nav-item>
        </b-nav>
      </b-card-header>

      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex align-items-baseline justify-content-center">
          <!-- Per Page -->
          <b-col cols="12" md="12" class="d-flex align-items-baseline justify-content-between mb-1 mb-md-0">
            <div>
              <label>Show</label>
              <v-select
                v-model="options.perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>
            <div>
              <div class="d-flex align-items-center justify-content-end">
                <b-form-checkbox v-model="show_hidden_listings" class="ml-2">Hidden Only</b-form-checkbox>
                <b-form-checkbox v-model="show_favourite_listings" class="ml-2">Favourites Only</b-form-checkbox>
                <b-button variant="flat-primary" @click="initialize()" class="ml-1">
                  <feather-icon icon="RefreshCwIcon" class="mr-25" />
                  <span class="align-middle">Refresh</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <template v-if="active_view == 'table'">
        <b-table
          ref="listingtable"
          primary-key="listing_id"
          :items="listings"
          :fields="tableColumns"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          :current-page.sync="options.currentPage"
          per-page="0"
          :filter="null"
          :api-url="null"
          :empty-text="busy ? 'Loading Data...' : 'No matching records found'"
          show-empty
          :busy="busy"
          @row-clicked="show_listing_sidebar"
          class="position-relative"
          sticky-header="80vh"
          hover
          head-variant="light"
          no-sort-reset
          dense
          small
          striped
          selectable
          :select-mode="selectMode"
          responsive="sm"
          @row-selected="onRowSelected"
        >
          <!-- slect checkbox -->
          <template #cell(selected)="data">
            <b-form-checkbox @change="checked(data.index, data.rowSelected)" v-model="data.rowSelected"></b-form-checkbox>
          </template>

          <!-- Column: address -->
          <template #cell(address)="data">
            <div class="d-flex align-items-baseline justify-content-between" style="padding: 10px 20px">
              <div class="pr-1">{{ get_address(data.item) }}</div>
            </div>
          </template>

          <!-- Column: price -->
          <template #cell(price)="data">
            <div class="d-flex align-items-baseline justify-content-between">
              <div class="pr-1">{{ data.item.manual_data && data.item.manual_data.price ? data.item.manual_data.price : data.item.price }}</div>
            </div>
          </template>

          <!-- Column: address -->
          <template #cell(city_plan_)="data">
            <div class="d-flex align-items-baseline justify-content-around">
              <div class="">{{ data.item.city_plan_ && permissions.property_data.includes("zone") ? data.item.city_plan_.replace(/QPP-/gi, "") : "-" }}</div>
            </div>
          </template>

          <!-- Column: address -->
          <template #cell(max_frontage)="data">
            <div class="d-flex align-items-baseline justify-content-around">
              <div class="">{{ data.item.max_frontage && permissions.property_data.includes("frontage") ? data.item.max_frontage.toFixed(1) : "-" }}</div>
            </div>
          </template>

          <!-- Column: address -->
          <template #cell(fall)="data">
            <div class="d-flex align-items-baseline justify-content-around">
              <div class="">{{ data.item.fall && permissions.property_data.includes("slope") ? parseFloat(data.item.fall).toFixed(1) : "-" }}</div>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(links)="data">
            <div class="d-flex align-items-baseline justify-content-center">
              <feather-icon
                @click.stop="favourite_listing(data.item.property_i)"
                icon="HeartIcon"
                size="18"
                class="link-icons"
                :color="'red'"
                :fill="data.item.favourite ? 'red' : 'white'"
                v-b-tooltip:click.d100.bottom.v-primary.title="'Add to favoutites'"
              />
              <!--  EyeOffIcon-->
              <feather-icon
                @click.stop="hide_listing(data.item.property_i)"
                :icon="!data.item.hidden ? 'EyeIcon' : 'EyeOffIcon'"
                size="18"
                class="link-icons"
                :color="!data.item.hidden ? 'green' : 'red'"
                v-b-tooltip:click.d100.top.v-primary.title="!data.item.hidden ? 'Hide All Lots' : 'Show All Lots'"
              />
              <font-awesome-icon
                @click.stop="open_link('https://www.google.com/search?q=' + get_address(data.item))"
                v-b-tooltip:hover.v-primary="{
                  customClass: '',
                  variant: 'info',
                  title: 'Google Search',
                  placement: 'topleft',
                  trigger: 'hover',
                }"
                color="#1f5a94"
                class="link-icons"
                icon="fa-brands fa-google"
              />
            </div>
          </template>
        </b-table>
      </template>
      <template v-if="active_view == 'map'">
        <listings-map
          :parent_busy="busy"
          :listings="null"
          @listing_clicked="show_listing_sidebar"
          :properties="listings"
          @property_clicked="show_listing_sidebar"
        ></listings-map>
      </template>

      <!-- pagination section -->
      <div class="mx-2 my-2">
        <b-row>
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start">
            <span class="text-muted">
              Showing {{ (options.currentPage - 1) * options.perPage }} to {{ (options.currentPage - 1) * options.perPage + options.perPage }} of
              {{ total_listings }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start">
            <b-pagination
              v-model="options.currentPage"
              :per-page="options.perPage"
              :total-rows="total_listings"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BFormCheckbox,
  BNav,
  BNavItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PropertySidebar from "./components/PropertySidebar.vue";
import BasicFilters from "./components/BasicFilters.vue";
import ListingsMap from "./components/ListingsMap.vue";

export default {
  name: "PropertySearch",
  components: {
    BasicFilters,
    PropertySidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    vSelect,
    VBTooltip,
    ToastificationContent,
    BFormCheckbox,
    ListingsMap,
    BCardHeader,
    BCardBody,
    BNav,
    BNavItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      active_view: "table",
      listings: [],
      loading: false,
      tableColumns: [
        // {
        //   key: "selected",
        //   label: "Select",
        //   sortable: false,
        // },
        {
          key: "address",
          label: "Address",
          sortable: true,
          variant: "warning",
          stickyColumn: true,
          thStyle: { width: "500px" },
          tdClass: "listings-table-td-address",
          thClass: "'listings-table-td-address'",
        },
        {
          key: "city_plan_",
          label: "Zone",
          sortable: true,
          formatter: (v, k, item) => (v ? v.replace("QPP-", "") : ""),
        },
        {
          key: "area",
          label: "Size",
          sortable: true,
          formatter: (v, k, item) => (v ? v.toFixed(1) : ""),
        },
        {
          key: "max_frontage",
          label: "Frontage",
          sortable: true,
          // formatter: (v, k, item) => (item.property.length && item.property[0].max_frontage ? item.property[0].max_frontage.toFixed(1) : ""),
        },
        {
          key: "fall",
          label: "Fall",
          sortable: true,
          formatter: (v, k, item) => (v ? v : ""),
        },
        { key: "property_t", label: "Type", sortable: false },
        // { key: "cover_typ_", label: "CType", sortable: false },
        // { key: "lot_format", label: "LFormat", sortable: false },
        // { key: "tenure_des", label: "Tenure", sortable: false },
        {
          key: "links",
          label: "Actions",
          thStyle: { "text-align": "center" },
        },
      ],

      // table options
      options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "property_i",
        sortDesc: true,
      },

      default_options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "property_i",
        sortDesc: true,
      },

      total_listings: 0,
      perPageOptions: [10, 50, 100, 150, 200, 250],
      busy: false,

      // sidebar fields
      sidebar: false,
      sidebar_listing: null,

      // filtrs options
      filters: [],
      not_filters: [],
      allowed_filters: [
        "postal_code",
        "suburb",
        "city_plan_",
        "area",
        "fall",
        "frontages",
        // "lot_format",
        //  "property_t",
        //  "tenure_des",
        "body_corporate",
      ],
      suburb_set_to_use: "bcc_suburbs",

      // favourites & hidden section
      show_hidden_listings: false,
      show_favourite_listings: false,
      // highlighting
      three_months_ago: moment().subtract(3, "months").format("YYYY-MM-DD"),
      last_two_dates: [0, 1, 2].map((d) => moment().subtract(d, "days").format("YYYY-MM-DD")),
      permissions: null,

      // table selection fields
      selectMode: "multi",
      selected: [],
    };
  },
  computed: {
    tableColumnsComputed() {
      console.log("Computed your columns yoooo....", this.tableColumns);
      return this.tableColumns;
    },
  },

  watch: {
    options: {
      deep: true,
      async handler() {
        await this.load_listings();
      },
    },
    async show_hidden_listings(newval) {
      await this.load_listings();
    },
    async show_favourite_listings(newval) {
      await this.load_listings();
    },
  },
  async mounted() {
    try {
      this.initialize();
      this.permissions = await this.$store.dispatch("get_permissions");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        // changing options would trigger load_listings() by the watcher
        this.options = Object.assign({}, this.default_options);
      } catch (error) {
        console.log(error);
      }
    },
    async load_listings() {
      try {
        if (this.busy) return;
        this.busy = true;
        await new Promise((r) => setTimeout(r, 200));
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getbccproperties`, {
          filters: this.filters,
          not_filters: this.not_filters,
          options: this.options,
          show_hidden: this.show_hidden_listings,
          show_favourite: this.show_favourite_listings,
        });
        // console.log(res.data);
        this.busy = false;
        // console.log(res.data);
        if (!res.data.success) throw new Error("listings could not be fetched");

        this.listings = res.data.properties;
        this.total_listings = res.data.count;
        gtag("event", "filters", {
          event_category: "Filters",
          event_label: "filters",
          value: JSON.stringify(this.filters),
        });
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },
    async search(filters) {
      this.filters = filters;
      this.options.currentPage != 1 ? (this.options.currentPage = 1) : await this.load_listings();
      // await this.load_listings();
    },
    set_filters(filters) {
      this.filters = filters;
    },
    show_listing_sidebar(listing, index) {
      this.sidebar_listing = listing;
      this.sidebar = true;
      console.log(listing);
      //   this.set_as_viewed(listing.listing_id, index);
    },
    open_link(link) {
      window.open(link);
    },
    domain_icon_color(data) {
      if (data && Object.keys(data).length && data["last_checked"]) {
        if (data["status"].toLowerCase() == "onmarket") return "orange";
        if (data["status"].toLowerCase() == "offmarket") return "green";
      }
      return "grey";
    },
    domain_icon_tooltip(data) {
      if (data && Object.keys(data).length && data["last_checked"]) return `Domain Data Available - ${data["status"]}`;
      else return "Domain Data Not Available";
    },
    property_data_icon_tooltip(condition) {
      if (condition) return `Property Data Available`;
      else return "Property Data Not Available";
    },
    async favourite_listing(id) {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/updatefavouriteproperty`, { id });
        this.busy = false;

        if (!res.data.success) throw new Error("Favourites could not be updated");

        // find listing
        // add favourite = true
        const index = this.listings.findIndex((l) => l.property_i == id);
        this.listings[index].favourite = res.data.added ? [{ property_i: res.data.property_i }] : [];
        this.showToast("info", "Info", `Favourites Updated`);
        await this.load_listings();
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Favourites could not be updated");
      }
    },
    async hide_listing(id) {
      try {
        console.log(id);
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/updatehideproperty`, { id });
        this.busy = false;

        if (!res.data.success) throw new Error("property status could not be updated");

        // find listing
        // add favourite = true
        const index = this.listings.findIndex((l) => l.property_i == id);
        this.listings[index].hidden = res.data.added ? { property_i: res.data.property_i } : null;
        // if added to hidden list
        if (!this.show_hidden_listings) {
          this.listings.splice(index, 1);
          this.total_listings--;
        }

        this.showToast("info", "Info", `Hidden Properties Updated - ${this.listings[index].address}`);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", "Hidden properties could not be updated");
      }
    },
    async set_as_viewed(id, index) {
      try {
        this.listings[index].viewed = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/setasviewed`, { id });
      } catch (error) {
        console.log(error);
      }
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },

    is_old(input_date) {
      return input_date && input_date < this.three_months_ago ? true : false;
    },
    updated_status(l) {
      let text = "";
      if (this.last_two_dates.includes(l.status_date)) {
        if (l.status_date == l.input_date) text = "(New)";
        else text = "(Updated)";
      }
      return text;
    },
    get_address(p) {
      let a = "";
      // street address
      if (p.unit_numbe) a += p.unit_numbe + "/";
      if (p.house_numb) a += p.house_numb;
      if (p.house_nu_1) a += p.house_nu_1;
      if (p.corridor_n) a += " " + p.corridor_n;
      if (p.corridor_s) a += " " + p.corridor_s;
      // area address
      if (p.suburb) a += ", " + p.suburb;
      a += ", QLD";
      if (p.postcode) a += ", " + p.postcode;

      return a;
    },

    // selection fields
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    checked(index, checked) {
      let tableRef = this.$refs.listingtable;
      // to select all use tableRef.selectAllRows()
      // to see all availabe table properties just do a console.log(tableRef)
      if (checked === false) {
        tableRef.selectRow(index);
      } else {
        tableRef.unselectRow(index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.link-icons {
  padding-right: 3px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
}

.small-text {
  font-size: 11px;
  font-weight: bold;
}

.old_listing {
  background-color: #ffd7d7;
}
.is_viewed {
  // background-color: #ffd7d7;
  // color: red;
  opacity: 0.5;
}

/*
area:106
city_plan_:"QPP-PC1"
unit_numbe:5305
house_numb:501
house_nu_1:"B"
corridor_n:"ADELAIDE"
corridor_s:"ST"
suburb:"BRISBANE CITY"
postcode:4000

fall:null
frontages:[]
max_frontage:null

cover_typ_:"STRATA"
lot_format:"BLD"
property_t:"U"
tenure_des:null

lotplan:"5305SP222627"
property_i:1106312
*/
</style>
