<template>
  <b-sidebar
    id="true"
    width="55%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header>
          <b-card-title>Property Details</b-card-title>
          <b-button variant="flat-primary" class="float-right" size="sm" @click="() => $emit('toggle_sidebar', false)">
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Close</span>
          </b-button>
        </b-card-header>
        <b-card-body v-if="permissions">
          <b-overlay :show="busy" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
            <!-- Listing data Section -->
            <b-row>
              <b-col cols="12" md="12" class="my-1">
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th class="text-center" colspan="2">User Data</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="listing_manual_data && Object.keys(listing_manual_data).length">
                      <b-tr class="my-1">
                        <b-th class="capitalize-text">Comments</b-th>
                        <b-td class="text-left">
                          <b-form-textarea v-model="comment_text" placeholder="Enter Comments" class="w-100" rows="2"></b-form-textarea>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="2">Comments can be added by adding property to favourites</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
                <b-button
                  variant="flat-primary"
                  class="float-right"
                  size="sm"
                  @click="save_comments()"
                  v-if="listing_manual_data && Object.keys(listing_manual_data).length"
                >
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle">Save</span>
                </b-button>
              </b-col>
            </b-row>
            <!-- Listing data Section -->
            <b-row>
              <b-col cols="12" md="9" class="pr-0">
                <b-overlay
                  class="exclude-filters-overlay-background"
                  :show="!permissions.sidebar_sections.includes('council_information')"
                  variant="transparent"
                  opacity="1.0"
                  blur="2.5px"
                >
                  <b-table-simple hover small caption-top responsive>
                    <b-thead head-variant="dark">
                      <b-tr>
                        <b-th class="text-center" colspan="2">Property information</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <template v-if="Object.keys(listing_data).length">
                        <template v-for="key of Object.keys(listing_data)">
                          <b-tr :key="key" v-if="!exclude_fields.includes(key)">
                            <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                            <b-td class="text-left">
                              <div v-if="link_fields.includes(key)" @click="link_clicked(listing_data[key])"><feather-icon icon="Link2Icon" /></div>
                              <div v-else>{{ format_values(key, listing_data[key]) }}</div>
                            </b-td>
                          </b-tr>
                        </template>
                      </template>
                      <template v-else>
                        <b-tr>
                          <b-td class="text-center" colspan="2">No Listing Data Found</b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </b-overlay>
              </b-col>
              <b-col cols="12" md="3" class="pl-0">
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th class="text-center" colspan="1">Links</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="Object.keys(listing_data).length">
                      <!-- independent links -->

                      <b-tr v-if="Object.keys(listing_data).length">
                        <b-th class="link-th" @click="link_clicked('https://www.google.com/search?q=' + get_address(listing_data))">
                          <feather-icon icon="SearchIcon" />
                          Search Google
                        </b-th>
                      </b-tr>

                      <!-- next link -->
                      <b-overlay
                        class="exclude-filters-overlay-background"
                        :show="!permissions.property_data.includes('council_information')"
                        variant="transparent"
                        opacity="0.7"
                        blur="2.5px"
                      >
                        <b-tr v-if="listing_data && listing_data.lotplan">
                          <b-th class="link-th" @click="link_clicked(`https://cityplan.brisbane.qld.gov.au/eplan/property/${listing_data.lotplan}/0/209?_t=property`)">
                            <feather-icon icon="Link2Icon" />
                            Council Lookup
                          </b-th>
                        </b-tr>
                      </b-overlay>

                      <!-- next link -->
                      <b-overlay
                        class="exclude-filters-overlay-background"
                        :show="!permissions.property_data.includes('development_approvals')"
                        variant="transparent"
                        opacity="0.7"
                        blur="2.5px"
                      >
                        <b-tr v-if="listing_data && listing_data.lot_id">
                          <b-th
                            class="link-th"
                            @click="link_clicked('https://developmenti.brisbane.qld.gov.au/Home/FilterDirect?filters=LandNumber=' + listing_data.lot_id)"
                          >
                            <feather-icon icon="Link2Icon" />
                            Developmenti
                          </b-th>
                        </b-tr>
                      </b-overlay>

                      <b-overlay
                        class="exclude-filters-overlay-background"
                        :show="!permissions.property_data.includes('water')"
                        variant="transparent"
                        opacity="0.7"
                        blur="2.5px"
                      >
                        <b-tr v-if="Object.keys(listing_data).length">
                          <b-th
                            class="link-th"
                            @click="
                              link_clicked(
                                'https://quu.maps.arcgis.com/apps/webappviewer/index.html?id=3d33e3db46894d46a9cffca08eae68b9&find=' + get_address(listing_data)
                              )
                            "
                          >
                            <feather-icon icon="Link2Icon" />
                            Water & Sewer
                          </b-th>
                        </b-tr>
                      </b-overlay>
                      <b-overlay
                        class="exclude-filters-overlay-background"
                        :show="!permissions.property_data.includes('stormwater')"
                        variant="transparent"
                        opacity="0.7"
                        blur="2.5px"
                      >
                        <b-tr v-if="Object.keys(listing_data).length">
                          <b-th
                            class="link-th"
                            @click="
                              link_clicked(
                                'https://www.arcgis.com/apps/webappviewer/index.html?id=0613d53beac44a088731063bf7fe64ee&find=' + get_address(listing_data)
                              )
                            "
                          >
                            <feather-icon icon="Link2Icon" />
                            Stormwater
                          </b-th>
                        </b-tr>
                      </b-overlay>
                      <b-overlay
                        class="exclude-filters-overlay-background"
                        :show="!permissions.property_data.includes('electrical')"
                        variant="transparent"
                        opacity="0.7"
                        blur="2.5px"
                      >
                        <b-tr v-if="Object.keys(listing_data).length">
                          <b-th
                            class="link-th"
                            @click="
                              link_clicked(
                                'https://ergon.maps.arcgis.com/apps/webappviewer/index.html?id=5a53f6f37db84158930f9909e4d30286&find=' +
                                  get_address(listing_data)
                              )
                            "
                          >
                            <feather-icon icon="Link2Icon" />
                            Electrical
                          </b-th>
                        </b-tr>
                      </b-overlay>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="2">No Links Found</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>

            <!-- Map Section Start -->
            <b-row>
              <b-col cols="12">
                <iframe
                  v-if="listing_data && get_address(listing_data)"
                  width="100%"
                  height="250"
                  style="border: 0"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  :src="
                    'https://www.google.com/maps/embed/v1/place?key=AIzaSyAVxI1NtkgyDjG9RniT8Mymq63x4E7jdi4&q=' + encodeURIComponent(get_address(listing_data))
                  "
                ></iframe>
              </b-col>
            </b-row>
            <!-- Map Section End -->

            <!-- Flags table -->
            <b-overlay
              class="exclude-filters-overlay-background"
              :show="!permissions.sidebar_sections.includes('council_flags')"
              :variant="'transparent'"
              :opacity="1.0"
              :blur="'2px'"
              rounded="sm"
              :spinner-variant="null"
            >
              <b-table-simple hover small caption-top responsive width="100%" class="mt-1">
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th class="text-left w-40">Flag Group</b-th>
                    <b-th class="text-left w-30">
                      Flag
                      <span class="small-text" style="margin-left: 2px; margin-bottom: 4px">(Hover for details)</span>
                    </b-th>
                    <b-th class="text-center w-30">
                      <span>Plan Reference</span>
                      <feather-icon icon="ExternalLinkIcon" small style="margin-left: 2px; margin-bottom: 4px" />
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="flags_data.length">
                    <template v-for="(flag, index) of flags_data">
                      <template v-if="!exclude_flags.includes(flag.flag)">
                        <b-tr :key="'tfaa' + index">
                          <b-td class="text-left">{{ flag["flagType"] }}</b-td>
                          <b-td class="text-left">
                            <div
                              v-b-tooltip:hover.viewport="{
                                title: '<div>Code: ' + flag['flag'] + '</div>' + flag['reportDescription'],
                                html: true,
                                trigger: 'hover',
                                placement: 'top',
                                variant: 'primary',
                                delay: 100,
                                customClass: 'flags-tooltip-container',
                              }"
                            >
                              <span style="font-size: 0.9em">{{ flag["reportTitle"] }}</span>
                            </div>
                          </b-td>
                          <b-td class="text-center">
                            <b-link href="https://cityplan.brisbane.qld.gov.au/eplan/#Rules/0/0/0/0/0" target="_blank">{{ flag["PlanSection"] }}</b-link>
                          </b-td>
                        </b-tr>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td class="text-center" colspan="3">{{ flags_request ? "Loading Flag Data..." : "No Flags Found For This Property" }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-overlay>
          </b-overlay>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import moment from "moment";
import {
  BSidebar,
  BCard,
  BBadge,
  BCardBody,
  BButton,
  BCardTitle,
  BCardHeader,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCol,
  BTfoot,
  BLink,
  VBTooltip,
  BRow,
  BFormInput,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BTbody,
    BTfoot,
    BThead,
    BTr,
    BTh,
    BTd,
    BCol,
    BSidebar,
    BCard,
    BBadge,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    BTableSimple,
    ToastificationContent,
    VBTooltip,
    BRow,
    BFormInput,
    BFormTextarea,
    BOverlay,
  },
  props: ["listing", "sidebar"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      exclude_fields: [
        "unit_numbe",
        "house_numb",
        "house_nu_1",
        "corridor_n",
        "corridor_s",
        "suburb",
        "postcode",
        "lotplan",
        "property_i",
        "frontages",
        "viewed",
        "favourite",
        "hidden",
        "lot_format",
        "cover_typ_",
        "tenure_des",
        "geom",
        "latlng",
      ],

      include: ["area", "city_plan_", "fall", "frontages", "max_frontage", "cover_typ_", "lot_format", "property_t", "tenure_des"],
      domain_include_fields: [
        "status",
        // "last_updated",
        "last_checked",
        "portal",
        // "zone",
        // "address",
        // "created",
        // "areaSize",
        // "features",
        // "isResidential",
        // "onMarketTypes",
        // "propertyCategory",
      ],
      link_fields: ["listing_web_address", "website"],
      column_mapping: {
        status_date: "Last Update",
        property_type_other: "Category",
        listing_web_address: "Source",
        area: "Area (SQM)",
        frontages: "frontage",
        city_plan_: "zone",
        max_frontage: "Frontage",
        cover_typ_: "Cover Type",
        lot_format: "Lot Format",
        property_t: "Property Type",
        tenure_des: "Tenure",
      },
      value_formatters: {
        area: (v) => (v ? v.toFixed(1) : v),
        max_frontage: (v) => (v ? v.toFixed(1) : v),
        fall: (v) => (v ? parseFloat(v).toFixed(1) : ""),
        frontages: (v) => (v ? v.toFixed(1) : v),
        zone: (v) => (v ? v.replace("QPP-", "") : v),
        city_plan_: (v) => (v ? v.replace("QPP-", "") : v),
        time_on_market: (v) => (v ? moment(v, "YYYY-MM-DD").fromNow(true) : v),
      },

      // tables data
      contact_section_fields: ["agents"],
      property_section_fields: ["property"],
      domain_section_fields: ["domain_data"],
      google_section_fields: ["google_data"],
      manual_section_fields: ["favourite"],

      listing_data: {},
      property_data: {},
      contact_data: {},
      flags_data: [],
      domain_data: {},
      google_data: {},
      listing_manual_data: {},

      // flags daa fields
      exclude_flags: ["Grid"],
      exclude_flag_fields: [],
      flag_tooltip: ["ReportDescription"],
      flags_request: false,

      // manual data fields
      comment_text: "",
      busy: false,
      permissions: null,
    };
  },
  watch: {
    listing: {
      deep: true,
      async handler(newval) {
        if (!newval || !Object.keys(newval).length) return;
        this.set_defaults();
        for (let [key, value] of Object.entries(newval)) {
          if (this.google_section_fields.includes(key)) this.google_data = value;
          else if (this.manual_section_fields.includes(key)) this.listing_manual_data = value ? value : null;
          //   else if (this.domain_section_fields.includes(key)) this.domain_data = value;
          //   else if (this.contact_section_fields.includes(key)) this.contact_data[key] = this.json_to_array(value);
          //   else if (this.property_section_fields.includes(key) && value && value.length) this.property_data = value[0];
          else this.listing_data[key] = value;
        }
        if (this.listing && Object.keys(this.listing).length) this.listing_data.address = this.get_address(this.listing);
        if (this.listing_manual_data && this.listing_manual_data.comments) this.comment_text = this.listing_manual_data.comments;
        this.get_flags();
      },
    },
  },
  async mounted() {
    this.permissions = await this.$store.dispatch("get_permissions");
  },
  methods: {
    async get_flags() {
      try {
        if (!this.listing || !this.listing.lotplan) return;
        let listing = {};
        listing.lotplan = this.listing.lotplan;
        this.flags_request = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getlistingflags`, { listing });
        this.flags_request = false;

        if (!res.data.success) throw new Error("Flags could not be fetched");
        this.flags_data = res.data.flags;
      } catch (error) {
        this.flags_request = false;
        console.log(error);
        this.showToast("danger", "Error", "Error while trying to fetch flags");
      }
    },
    async save_comments() {
      try {
        if (!this.listing || !this.listing.property_i || !this.listing.favourite) throw new Error("Only favourite properties can have comments");
        if (!this.comment_text) throw new Error("Commment should not be empty");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/savepropertycomments`, {
          property_i: this.listing.property_i,
          comments: this.comment_text,
        });
        this.busy = false;

        if (!res.data.success) throw new Error("Comments could not be saved");
        this.listing.favourite.comments = res.data.comments;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while trying to save comments");
      }
    },
    json_to_array(v) {
      try {
        v = JSON.parse(v);
        return Array.isArray(v) ? v : [v];
      } catch (error) {
        return [];
      }
    },
    set_defaults() {
      this.listing_data = {};
      this.property_data = {};
      this.flags_data = {};
      this.contact_data = {};
      this.comment_text = "";
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    map_fields(key) {
      if (Object.keys(this.column_mapping).includes(key)) key = this.column_mapping[key];
      return key;
    },
    format_values(key, value) {
      if (Object.keys(this.value_formatters).includes(key)) value = this.value_formatters[key](value);
      return value;
    },
    link_clicked(value) {
      if (!value) return;
      value = value.includes("http") ? value : "https://" + value;
      window.open(value);
    },
    type(v) {
      return typeof v;
    },
    sanitize_url(url) {
      return url.replace();
    },
    get_address(p) {
      let a = "";
      // street address
      if (p.unit_numbe) a += p.unit_numbe + "/";
      if (p.house_numb) a += p.house_numb;
      if (p.house_nu_1) a += p.house_nu_1;
      if (p.corridor_n) a += " " + p.corridor_n;
      if (p.corridor_s) a += " " + p.corridor_s;
      // area address
      if (p.suburb) a += ", " + p.suburb;
      a += ", QLD";
      if (p.postcode) a += ", " + p.postcode;
      return a;
    },
  },
  filters: {
    format_fields(str) {
      return typeof str == "string"
        ? str
            .replace(/_/g, " ")
            .replace(/([A-Z]{1}[a-z]+)/g, " $1")
            .trim()
        : str;
    },
    formatDate: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.flags-tooltip-container.tooltip .tooltip-inner {
  max-width: 500px !important;
  width: 400px !important;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
.w-33 {
  width: 33%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.listing_table_fields {
  border-bottom: 1px solid rgb(224, 221, 221);
}
.link-th {
  line-height: 20px;
}
.link-th svg {
  margin-right: 5px;
  color: rgb(87, 87, 177);
}
</style>
